

























































































import { Component, Vue, Prop } from 'vue-property-decorator'

type Type = '' | 'model' | 'single'

@Component
export default class QuestionItem extends Vue {
  @Prop({ default: '' }) type!: string
  @Prop({ default: true }) correct!: boolean
  @Prop() question!: Question
  @Prop() answerOptionId!: string
  @Prop() questionResults!: QuestionResults

  isVisible = false

  get questionTime () {
    return {
      minutes: Math.floor(this.question.time / 60),
      seconds: this.question.time % 60
    }
  }

  get questionTileClasses () {
    return {
      'p-0': true,
      'w-full': true,
      'lg:w-4/6': this.type === ''
    }
  }
}
