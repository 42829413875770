








































import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'

import QuestionItem from '@/partials/components/QuestionItem.vue'

@Component({
  components: {
    QuestionItem
  }
})
export default class ExamsReportCandidate extends Vue {
  examCandidateResult: ExamCandidateResult = {
    serviceUser: {
      user: {
        name: ''
      }
    },
    examSchedule: {
      scheduledTo: ''
    },
    answersGraph: [] as Array<DonutDataGraph>
  } as ExamCandidateResult

  created () {
    const { examId, scheduleId, scheduleServiceUserId } = this.$route.params
    axios.get(`exam/${examId}/schedule/${scheduleId}/user/${scheduleServiceUserId}`)
      .then(response => {
        this.examCandidateResult = camelCaseKeys(response.data.data, { deep: true })
        this.donutChart.data = this.examCandidateResult.answersGraph
      })
  }

  donutChart = {
    data: [] as any,
    options: {
      title: '',
      resizable: true,
      legend: {
        alignment: 'center'
      },
      donut: {
        center: {
          label: 'Questões'
        },
        alignment: 'center'
      },
      height: '400px'
    }
  }
}
